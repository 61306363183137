<template>
  <div>
    <div v-if="this.tokenStatus == 0" class="d-flex flex-column flex-column-fluid flex-lg-row">
      <div class="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
        <div class="d-flex flex-center flex-lg-start flex-column text-start">
          <a href="/" class="mb-7">
            <img alt="Logo" src="assets/theme/media/logos/logo-cc-white.png">
          </a>
          <h2 class="text-white fw-normal m-0 ms-4">Set a password to verify your account.</h2>
        </div>
      </div>
      <div class="d-flex flex-center w-lg-50 p-10">
        <div class="card rounded-3 w-md-550px pt-15 pb-15 mt-20 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow" style="background-image: url('assets/theme/media/svg/shapes/abstract-1.svg')">
          <div class="card-body p-20 p-lg-20">
            <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_new_password_form">
              <div class="text-center mb-10">
                <h1 class="fw-bolder text-gray-700 mb-3">
                  Hi {{this.userName}}
                </h1>
                <div class="text-gray-500 fw-semibold fs-6 mb-7">  Set a password to verify your account and log in to the system with the e-mail address that you entered. </div>
                <div class="text-gray-500 fw-semibold fs-7">  Create a password 8-16 characters long, including uppercase, lowercase and special characters. </div>
              </div>
              <div class="fv-row mb-8 fv-plugins-icon-container">
                <div class="mb-1">
                  <div class="position-relative">    
                    <input id="pw_password" class="form-control" type="password" v-model="form.password" maxlength="16" placeholder="Password" name="password" autocomplete="off"
                    :class="{'border border-success': isPasswordConfirm == true}">
                    <!--begin::Visibility toggle-->
                    <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility()">
                        <i v-if="isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                        <i v-if="!isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                    </span>
                    <!--end::Visibility toggle-->
                    <password-meter class="mb-3" @score="onScore" :password="form.password"/>
                  </div>
                </div>                
                <div class="d-flex flex-stack mb-3 ms-1">
                  <div class="text-gray-500 fw-semibold">
                    Including 3 of the following:
                  </div>
                  <div class="text-gray-500 fw-semibold">
                    Must Contain:
                  </div>
                </div>
                <div class="d-flex flex-stack mb-3 ms-1">
                  <div class="text-muted">
                    <span class="me-3"><i class="fas" :class="isHasUppercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> ABC</span>
                    <span class="me-3"><i class="fas" :class="isHasLowercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> abc</span>
                    <span class="me-3"><i class="fas" :class="isHasNumber ? 'fa-check text-success' : 'fa-times text-danger'"></i> 123</span>
                    <span class="me-3"><i class="fas" :class="isHasSpecial ? 'fa-check text-success' : 'fa-times text-danger'"></i> @#$</span>
                  </div>
                  <div class="text-muted">
                    <span> <i class="fas" :class="form.password.length > 8 ? 'fa-check text-success' : 'fa-times text-danger'"></i> 8-16 Chars </span>
                  </div>
                </div>
              </div>

              <div class="fv-row mb-8 fv-plugins-icon-container">    
                <input type="password" placeholder="Repeat Password" name="confirm-password" v-model="form.password_confirm" maxlength="16" autocomplete="off" class="form-control" 
                 @paste.prevent :class="{'border border-success': isPasswordConfirm == true, 'border border-danger': isPasswordConfirm == false}"> 
                <small class="text-danger" v-if="isPasswordConfirm == false">
                  The password and its confirm are not the same
                </small>
                <div class="fv-plugins-message-container invalid-feedback"></div>
              </div>
              <div class="d-grid mb-10">
                <button type="button" v-if="verificationType == null || verificationType == undefined || verificationType == ''" id="kt_new_password_submit" class="btn btn-primary" @click="onResetPassword()" data-kt-indicator="on" :disabled="passwordScore < 3 || isPasswordConfirm !== true">
                  <strong v-if="!this.isProggressing">Submit</strong>
                  <span class="indicator-progress" v-if="this.isProggressing">
                    Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                <button type="button" v-if="verificationType !== null && verificationType !== undefined && verificationType !== ''" id="kt_new_password_submit" class="btn btn-primary" @click="onSetPassword()" data-kt-indicator="on" :disabled="passwordScore < 3 || isPasswordConfirm !== true">
                  <strong v-if="!this.isProggressing">Set Password</strong>
                  <span class="indicator-progress" v-if="this.isProggressing">
                    Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> 

    <div v-if="this.tokenStatus == 1 || this.tokenStatus == 2" class="d-flex flex-column flex-center text-center p-10">        
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
            <span v-if="tokenStatus == 1">URL is invalid!</span>  
            <span v-if="tokenStatus == 2">Token expired!</span>  
          </h1>
          <div class="fw-semibold fs-6 text-gray-500 mb-8">
            To receive a new link, contact to your manager.
          </div>
          <div class="mb-10">
              <img v-if="tokenStatus == 1" src="assets/theme/media/illustrations/unitedpalms-1/18.png" class="mw-100 mh-200px theme-light-show" alt="">
              <img v-if="tokenStatus == 2" src="assets/theme/media/illustrations/unitedpalms-1/5.png" class="mw-100 mh-200px theme-light-show" alt="">
          </div>
          <div class="mb-10 fw-semibold fs-6 text-gray-500">
            <router-link to="/Login">
              <a href="#" class="btn btn-primary fw-bold">
                Back to Login
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.tokenStatus == 3" class="d-flex flex-column flex-center text-center p-10">        
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
            Company Created!
          </h1>
          <div class="fw-semibold fs-6 text-gray-500 mb-8">
            You can log in to the system with the e-mail address that you verified.
          </div>
          <div class="mb-10">
              <img src="assets/theme/media/illustrations/unitedpalms-1/10.png" class="mw-100 mh-200px theme-light-show" alt="">
          </div>
          
          <div class="d-flex flex-center pb-20">
            <router-link to="/Login">
              <a href="#" class="btn btn-lg btn-primary">
                Login
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PasswordMeter from "../scripts/passwordMeter.js";
export default {
  name: "VerifyAccount",
  components: {
    PasswordMeter
  },
  data() {
    return {
      form: {
        password: "",
        password_confirm: "",
      },
      tokenStatus: -1,
      tokenType: 0,
      passwordScore: 0,
      isHide: true,
      verificationType: this.$route.query.type,
      userName: null,
      isProggressing: false
    };
  },
  computed: {
    isPasswordConfirm(){
      if(this.form.password !== null && this.form.password !== '') {
        if(this.form.password == this.form.password_confirm) return true;
        else return false;
      }
      else return null;
    },
    isHasNumber() {
      return /(.*[0-9].*)/g.test(this.form.password);
    },
    isHasLowercase() {
      return /(.*[a-z].*)/g.test(this.form.password);
    },
    isHasUppercase() {
      return /(.*[A-Z].*)/g.test(this.form.password);
    },
    isHasSpecial() {
      return /[^A-Za-z0-9]/g.test(this.form.password);
    }
  },
  methods: {
    getData(){
      document.body.style.backgroundImage = "url('/assets/theme/media/auth/bg7-dark.jpg')";
      document.body.classList.add('app-blank');
      document.body.classList.add('bgi-size-cover');
      document.body.classList.add('bgi-position-center');
      document.body.classList.add('bgi-no-repeat');

      if(!this.$route.query.code) {return this.$router.push({ name: 'Login' });}

      const parameters = {
        code: this.$route.query.code,
        type: 2
      };  
      if(this.verificationType !== null && this.verificationType !== undefined && this.verificationType !== '') {
        parameters.type = 3;
      }
      axios.post('/api/Auth/CheckTokenExpired', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.tokenStatus = response.data.tokenStatus;
          this.userName = response.data.name_surname;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    onResetPassword() {
      if(this.form.password == null || this.form.password == '') return;
      if(this.form.password !== this.form.password_confirm) {
        this.$swal("Failed", "The passwords you entered do not match..!", 'error');
        return;
      }
      else if(this.passwordScore < 3) {
        this.$swal("Failed", "Please specify a strong password..!", 'error');
        return;
      }
      else {
        this.isProggressing = true;
        const that = this;
        const parameters = {
          code: this.$route.query.code,
          user_password: this.form.password,
          type: 2
        };  
        axios.post('/api/Auth/ResetPassword', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Password set to an account!", 'success');
            this.$router.push({ name: 'Login' });
          }
        })
        .catch(function (error) {
           console.log(error);
        })
        .finally(function () { 
          setTimeout(() => {
            that.isProggressing = false; 
          }, 500);
        });
      }
    },
    onSetPassword() {
      if(this.form.password == null || this.form.password == '') return;
      if(this.form.password !== this.form.password_confirm) {
        this.$swal("Failed", "The passwords you entered do not match..!", 'error');
        return;
      }
      else if(this.passwordScore < 3) {
        this.$swal("Failed", "Please specify a strong password..!", 'error');
        return;
      }
      else {
        this.isProggressing = true;
        const that = this;
        const parameters = {
          code: this.$route.query.code,
          user_password: this.form.password
        };  
        axios.post('/api/NewCompany/SetPassword', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.tokenStatus = 3;
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () { 
          setTimeout(() => {
            that.isProggressing = false; 
          }, 500);
        });
      }
    },
    onScore(pwScore) {
      this.passwordScore = pwScore.score;
    },
    togglePasswordVisibility() {
      this.isHide = !this.isHide;
      var x = document.getElementById("pw_password");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    }
  },
  created() {
     this.getData();
  }
};
</script>
<style scoped>
body{
  background-image: url('/assets/theme/media/auth/bg7-dark.jpg');
}

@media (min-width: 992px){
.flex-lg-start {
    justify-content: start;
    align-items: start;
}}
</style>