<template>
    <div>
        <div class="stepper stepper-links" data-kt-stepper="true">
            <div class="card p-1 shadow-sm">
                <div class="card-header border-0 min-h-30px">
                    <div class="card-title">
                        <div class="card-label fs-5 text-gray-800">
                            Your current plan is 
                            <b class="fw-boldest">{{ userData.licence_name }}</b>.
                            <br v-if="userData.is_v_ac">
                            <span v-if="userData.is_v_ac">
                                You have included <b>Accounting Module</b> in your plan.
                                Accounting will expire in <b :class="{'text-danger': userData.accounting_period_end <= 15}">{{parseInt(userData.accounting_period_end)}}</b> days.
                            </span> 
                            You have active <b class="fw-boldest">{{userData.user_count}}</b> users.
                            <br>
                            <small v-if="userData.is_v_dc == 1 || userData.is_v_lb == 1">
                                <span class="fw-boldest"> Included modules: </span>
                                <span class="mx-3" v-if="userData.is_v_dc == 1"> <span class="bullet bullet-dot mb-1 bg-primary"></span> Documents</span>
                                <span class="mx-3" v-if="userData.is_v_lb == 1"> <span class="bullet bullet-dot mb-1 bg-info"></span> Liabilities</span>
                            </small>
                        </div>
                    </div>
                    <div class="card-toolbar">                                    
                        <span class="fs-5 fw-bolder" v-if="userData.period_end > 0"> 
                           Expires in <b :class="{'text-danger': userData.period_end <= 15}">{{parseInt(userData.period_end)}}</b> days
                        </span>
                        <span class="text-danger fs-5 fw-bolder" v-if="userData.period_end < 0"> Expired {{Math.abs(userData.period_end)}} days ago</span>
                        <span class="text-danger fs-5 fw-bolder" v-if="userData.period_end == 0"> Expired today</span>  
                    </div>
                </div>
            </div>
            <div class="card mt-5 p-1 shadow-sm">
                <div class="card-body">
                    <div class="d-flex flex-column" :class="{'current': this.activeStep == 1}" v-if="this.activeStep == 1">
                        <div class="text-center" v-if="this.activeStep == 1">
                            <h1 class="mb-5">Upgrade a New Plan</h1>                            
                            <div class="text-muted fw-semibold fs-5 mb-5" v-if="this.isViewLicenceGuid == true" :menuSelection="this.menuSelection">
                                If you need more info, please check <a href="#" class="link-primary fw-bold" @click="openHelpPage()" >Licence Plans Guidelines</a>.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 mb-10 mb-lg-0">
                                <div class="nav flex-column" role="tablist" v-for="item in licenceList" :key="item">
                                    <a href="#" class="nav-link btn btn-outline btn-color-dark btn-active d-flex flex-stack text-start text-gray-800 p-4 mb-3 shadow btn-lighten" @click="setDetail(item)"
                                        data-bs-toggle="tab" data-bs-target="#kt_upgrade_plan_trial" aria-selected="false" role="tab" tabindex="-1"
                                        :class="{'active': licenceDetails.id == item.id,
                                        'btn-active-warning': item.parameter_type == 1,
                                        'btn-active-success': item.parameter_type == 2, 
                                        'btn-active-primary': item.parameter_type == 3,
                                        'btn-active-info': item.parameter_type == 4,
                                        'btn-active-secondary': item.parameter_type == 5}">
                                        <div class="d-flex align-items-center me-2">
                                            <div class="form-check form-check-custom form-check-solid flex-shrink-0 me-6"
                                            :class="{'form-check-warning': item.parameter_type == 1, 
                                            'form-check-success': item.parameter_type == 2, 
                                            'form-check-primary': item.parameter_type == 3, 
                                            'form-check-info': item.parameter_type == 4,
                                            'form-check-secondary': item.parameter_type == 5}">
                                                <input class="form-check-input" type="radio" name="plan" :checked="licenceDetails.id == item.id" value="trial">
                                            </div>
                                            <div class="flex-grow-1">
                                                <div class="d-flex align-items-center fs-5 fw-bold flex-wrap">
                                                    {{item.parameter_type_name}}
                                                </div>
                                                <div class="fw-semibold opacity-75">
                                                    {{item.description}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ms-5" v-if="item.parameter_type !== 5">
                                            <span class="mb-2"></span>
                                            <span class="fs-1 fw-bold me-1">{{item.period_day}}</span>
                                            <span class="fs-5 opacity-50">Days</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-6" v-if="licenceDetails.id !== undefined && licenceDetails.id !== null && licenceDetails.id !== ''">
                                <div class="rounded h-auto bg-light p-5 pb-5 pt-10">
                                    <!-- Add User -->
                                    <div class="d-flex flex-row justify-content-center" v-if="licenceDetails.parameter_type == 5">
                                        <div class="card card-rounded card-bordered min-w-200px py-3 mb-12 text-center me-10 cursor-pointer licence-card bg-gradient"
                                            :class="{'bg-primary text-white licence-card-active': activePriceView == 'monthly', 'bg-info text-white licence-card-active': activePriceView == 'annual'}">
                                            <div class="fs-3x d-flex justify-content-center align-items-start">
                                                <span class="lh-sm fw-semibold" v-if="activePriceView == 'monthly'">{{licenceDetails.user_price}}</span>
                                                <span class="lh-sm fw-semibold" v-if="activePriceView == 'annual'">{{upgradeForm.userCalculatedPrice}}</span>
                                                <!-- <span class="lh-sm fw-semibold" v-if="activePriceView == 'annual'">{{licenceDetails.user_price_annual}}</span> -->
                                                <span class="fs-5 mt-6">{{userData.currency_code}}</span>
                                            </div>
                                            <div class="fs-2 fw-bold" v-if="activePriceView == 'monthly'">Monthly</div>
                                            <div class="fs-2 fw-bold" v-if="activePriceView == 'annual'">{{userData.period_end}} Days</div>
                                            <div class="fs-7 fw-semibold mt-3" v-if="activePriceView == 'monthly'"> Only Credit Card </div>
                                            <div class="fs-7 fw-semibold mt-3" v-if="activePriceView == 'annual'"> Credit Card & Bank Transfer </div>
                                        </div>
                                    </div>
                                    <!-- Other Licence Packages -->
                                    <div class="d-flex flex-row justify-content-center" v-if="licenceDetails.parameter_type !== 5 && !isTrialPackage">
                                        <div class="card card-rounded card-bordered min-w-200px py-3 mb-12 text-center me-10 cursor-pointer licence-card bg-gradient" @click="onChangePeriod('annual')"
                                            :class="{'bg-info text-white licence-card-active': activePriceView == 'annual'}">
                                            <div class="fs-3x d-flex justify-content-center align-items-start">
                                                <span class="lh-sm fw-semibold">{{licenceDetails.price_annual}}</span>
                                                <span class="fs-5 mt-6">{{userData.currency_code}}</span>
                                            </div>
                                            <div class="fs-2 fw-bold">{{licenceDetails.period_day}} Days</div>
                                            <div class="fs-7 fw-semibold mt-3">
                                                Credit Card & Bank Transfer
                                            </div>
                                            <div class="fs-7 text-success fw-bold mt-5" v-if="licenceDetails.price !== '0.00'">
                                               <i class="bi bi-check-circle text-success"></i> Save {{onCalculateSaving}} {{userData.currency_code}}
                                            </div>
                                        </div>
                                        <div class="card card-rounded card-bordered min-w-200px py-3 mb-12 text-center cursor-pointer licence-card bg-gradient" @click="onChangePeriod('monthly')"
                                            v-if="licenceDetails.price !== '0.00'" :class="{'bg-primary text-white licence-card-active': activePriceView == 'monthly'}">
                                            <div class="fs-3x d-flex justify-content-center align-items-start">
                                                <span class="lh-sm fw-semibold">{{licenceDetails.price}}</span>
                                                <span class="fs-5 mt-6">{{userData.currency_code}}</span>
                                            </div>
                                            <div class="fs-2 fw-bold">Monthly</div>
                                            <div class="fs-7 fw-semibold mt-3">
                                                Only Credit Card
                                            </div>
                                            <div class="fs-7 fw-bolder mt-3">
                                                Paid in {{upgradeForm.payment_period}} installments
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Trial Packages (Free) -->
                                    <div class="d-flex flex-row justify-content-center" v-if="isTrialPackage">
                                        <div class="card card-rounded card-bordered min-w-200px py-3 mb-12 text-center me-10 cursor-pointer licence-card bg-gradient"
                                            :class="{'bg-primary text-white licence-card-active': activePriceView == 'monthly', 'bg-info text-white licence-card-active': activePriceView == 'annual'}">
                                            <div class="fs-3x d-flex justify-content-center align-items-start">
                                                Trial
                                            </div>
                                            <div class="fs-2 fw-bold">{{licenceDetails.period_day}} Days</div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <div class="flex-grow-1">
                                            <div class="card card-flush bg-body">
                                                <div class="card-body p-5">  
                                                    <div v-if="licenceDetails.parameter_type !== 3">
                                                        <table class="table align-middle gs-0 gy-4 my-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="pe-0 text-gray-800">
                                                                        <div class="d-flex align-items-center fs-5 fw-bold">
                                                                            Add user to your plan
                                                                        </div>
                                                                        <div>                                                                            
                                                                            <small>
                                                                                (<b v-if="activePriceView == 'monthly'">+{{licenceDetails.user_price}} </b>
                                                                                <b v-else>+{{licenceDetails.user_price_annual}} </b>
                                                                                {{userData.currency_code}} per user.)
                                                                            </small>
                                                                        </div>
                                                                    </td>
                                                                    <td class="pe-0 float-end">
                                                                        <div class="position-relative d-flex align-items-center">
                                                                            <button type="button" class="btn btn-icon btn-sm btn-light btn-icon-gray-400" :disabled="upgradeForm.user_count == 0" @click="onChangePrice('minus')">
                                                                                <i class="bi bi-dash fs-3x"></i>
                                                                            </button>

                                                                            <CurrencyInput type="text" class="border-0 text-center px-0 fs-3 fw-bold text-gray-800 w-50px" :precision="0" v-model="upgradeForm.user_count" @focus="$event.target.select()" :min="0" @blur="calculateSubTotal()"/>

                                                                            <button type="button" class="btn btn-icon btn-sm btn-light btn-icon-gray-400" @click="onChangePrice('plus')">
                                                                                <i class="bi bi-plus fs-3x"></i>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-end w-150px">
                                                                        <span class="fw-bold fs-2" :class="{'text-primary': activePriceView == 'monthly', 'text-info': activePriceView == 'annual'}">
                                                                            {{upgradeForm.user_total}} {{userData.currency_code}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="separator my-6" v-if="licenceDetails.parameter_type !== 3"></div>
                                                    
                                                    <div class="d-flex flex-stack justify-content-end" v-if="!isTrialPackage">  
                                                        <div class="fs-6 fw-bold text-end me-10">
                                                            <span class="d-block fs-3 lh-2">Subtotal:</span>
                                                        </div>
                                                        <div class="fs-6 fw-bold text-end">
                                                            <span class="d-block fs-2 lh-2">
                                                                {{upgradeForm.subtotal}} {{userData.currency_code}}
                                                                <small class="fs-7" v-if="activePriceView == 'monthly'">(Mon)</small>
                                                                <small class="fs-7" v-if="activePriceView == 'annual' && licenceDetails.parameter_type !== 5">({{licenceDetails.period_day}} Days)</small>
                                                                <small class="fs-7" v-if="activePriceView == 'annual' && licenceDetails.parameter_type == 5">({{userData.period_end}} Days)</small>
                                                            </span>
                                                        </div> 
                                                    </div>

                                                    <div class="fw-bold" v-if="isTrialPackage">
                                                        In the next step, you can confirm your request and start the trial. <i class="bi bi-arrow-right ms-5"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-end align-items-center mt-10 text-gray-600" v-if="!isTrialPackage">
                                                You can view the plan details in the next step. <i class="bi bi-arrow-right ms-5"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                    <div class="row" :class="{'current': this.activeStep == 2}" v-if="this.activeStep == 2">
                        <div class="row mb-10 mb-xl-0">
                            <h1 class="fw-bold text-gray-800 mb-5">Plan Details</h1>
                            <div class="col-lg-12 pb-5">
                                <div class="table-responsive">
                                    <table class="table mb-3">
                                        <thead>
                                            <tr class="border-bottom fs-6 fw-bold text-muted">
                                                <th class="min-w-175px pb-2">Description</th>
                                                <th class="min-w-70px  text-end pb-2">Plan Type</th>
                                                <th class="min-w-80px  text-end pb-2">Total Price</th>
                                                <th class="min-w-100px text-end pb-2">VAT
                                                    <span v-if="this.licenceDetails.is_add_vat == 1">({{parseInt(this.licenceDetails.vat_rate)}}%)</span>
                                                    <span v-if="this.licenceDetails.is_add_vat == 0">(None)</span>
                                                </th>
                                                <th class="min-w-100px text-end pb-2">Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr class="fw-bold text-gray-700 fs-5 " v-for="item in licencePlanDetails" v-bind:key="item">
                                                <td class="d-flex align-items-center pt-6">                                            
                                                    <i class="bi bi-diamond-fill fs-10 me-2"
                                                        :class="{ 'text-warning': item.parameter_type == 1, 'text-success': item.parameter_type == 2, 'text-primary': item.parameter_type == 3,
                                                        'text-info': item.parameter_type == 4, 'text-secondary': item.parameter_type == 5}">
                                                    </i>
                                                    {{item.plan_name}}
                                                    <span v-if="item.parameter_type !== 5 && activePriceView == 'monthly'"> 
                                                        ({{item.monthly_price}} {{userData.currency_code}} x {{upgradeForm.payment_period}} installments) 
                                                    </span>
                                                    <span v-if="item.parameter_type == 5 && activePriceView == 'monthly'"> ({{item.user_price}} {{userData.currency_code}} x {{item.user_count}} User) </span>
                                                    <span v-if="item.parameter_type == 5 && activePriceView == 'annual'"> ({{upgradeForm.userCalculatedPrice}} {{userData.currency_code}} x {{item.user_count}} User) </span>
                                                </td>

                                                <td class="pt-6 text-end">{{item.plan_type}}</td>
                                                <td class="pt-6 text-end">{{item.price}}</td>
                                                <td class="pt-6 text-end">{{item.vat_amount}}</td>
                                                <td class="pt-6 text-end text-gray-800 fw-bolder">{{item.total}}</td>
                                            </tr>
                                            <tr class="pt-6 border-top border-gray-300">
                                                <td class="pt-6 text-end fw-bold text-gray-600 fs-6" colspan="4">Subtotal</td>
                                                <td class="pt-6 text-end fw-bold fs-6 text-gray-800">{{this.totals.sub_total}} {{userData.currency_code}}</td>
                                            </tr>
                                            <tr>
                                                <td class="pt-3 text-end fw-bold text-gray-600 fs-6" colspan="4">VAT                                                    
                                                    <span v-if="this.licenceDetails.is_add_vat == 1">({{parseInt(this.licenceDetails.vat_rate)}}%)</span>
                                                    <span v-if="this.licenceDetails.is_add_vat == 0">(None)</span>
                                                </td>
                                                <td class="pt-3 text-end fw-bold fs-6 text-gray-800" v-if="this.licenceDetails.is_add_vat == 1">{{this.totals.vat_amount}} {{userData.currency_code}}</td>
                                                <td class="pt-3 text-end fw-bold fs-6 text-gray-800" v-if="this.licenceDetails.is_add_vat == 0">None</td>
                                            </tr>
                                            <tr>
                                                <td class="pt-3 text-end fw-bold fs-3 text-gray-800" colspan="4">Grand Total</td>
                                                <td class="pt-3 text-end fw-bolder fs-3 text-gray-800">{{this.totals.grand_total}} {{userData.currency_code}}</td>
                                            </tr>
                                            <tr v-if="activePriceView == 'monthly'">
                                                <td class="pt-3 text-end fw-bold fs-3 text-gray-800" colspan="4">Monthly Price</td>
                                                <td class="pt-3 text-end fs-3">
                                                    <div class="fw-bolder text-gray-800">{{this.totals.monthly_price_total}} {{userData.currency_code}} </div>
                                                    <div class="fw-bolder fs-7"> (Paid in {{upgradeForm.payment_period}} installments) </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="separator"></div>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <div class="flex-grow-1 mt-10">
                                <h1 class="fw-bold text-gray-800 mb-5">Payment Method</h1>
                                <div class="d-flex flex-column">
                                    <div class="row">
                                        <div class="col-lg-6" :class="{'col-lg-12': activePriceView == 'monthly'}">
                                            <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-3"
                                                :class="{'active btn-active-light-primary': upgradeForm.payment_method == 1}">
                                                <div class="d-flex align-items-center me-2">
                                                    <div class="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                        <input class="form-check-input" v-model="upgradeForm.payment_method" type="radio" name="payment_plan" value="1">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <h2 class="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                                            CREDIT CARD
                                                        </h2>
                                                        <div class="fw-semibold opacity-75">
                                                            In the next step, you can complete the payment process with a credit card.
                                                            <div class="mt-3 fs-7 text-gray-700" v-if="activePriceView == 'monthly' && licenceDetails.parameter_type !== 5">
                                                                By choosing the <b>{{licenceDetails.period_day}} Days </b> payment plan, you can make your payment with the <b>Bank Transfer</b> option.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ms-5">
                                                    <i class="bi bi-credit-card fs-3x"></i>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="col-lg-6" v-if="activePriceView == 'annual'">
                                            <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-3"
                                                :class="{'active btn-active-light-info': upgradeForm.payment_method == 2}">
                                                <div class="d-flex align-items-center me-2">
                                                    <div class="form-check form-check-custom form-check-solid form-check-info me-6">
                                                        <input class="form-check-input" v-model="upgradeForm.payment_method" type="radio" name="payment_plan" value="2">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <h2 class="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                                            BANK TRANSFER
                                                        </h2>
                                                        <div class="fw-semibold opacity-75">
                                                            In the next step, you can complete the payment process with the Bank Transfer method.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ms-5">
                                                    <i class="bi bi-cash-stack fs-3x"></i>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" :class="{'current': this.activeStep == 3}" v-if="this.activeStep == 3">
                        <div class="card card-flush" v-if="upgradeForm.payment_method == 1">
                            <div class="card-body text-center">
                                <div class="fw-bolder fs-2x text-gray-900 mb-4">                                    
                                    <div class="mb-2">
                                        <span class="text-gray-800 fw-bold me-1" v-if="activePriceView == 'monthly'">
                                            <span class="me-3">Grand Total:</span>
                                            <b>{{this.totals.monthly_price_total}} {{userData.currency_code}}</b>
                                        </span> 
                                        <span class="text-gray-800 fw-bold me-1" v-else>
                                            <span class="me-3">Grand Total:</span>
                                            <b>{{this.totals.grand_total}} {{userData.currency_code}}</b>
                                        </span> 
                                        <small class="text-gray-600 fw-semibold" v-if="activePriceView == 'monthly'">(Per Month)</small>
                                    </div>
                                    <div class="fs-3 text-gray-800 fw-semibold">
                                        {{licenceDetails.parameter_type_name}} ({{licenceDetails.period_day}} Days) 
                                        <span v-if="upgradeForm.user_count > 0"> + {{upgradeForm.user_count}} Users </span>
                                    </div>
                                </div>
                                <div class="fw-semibold fs-6 text-gray-500 mb-7">
                                    We have received your request. You can start using your new plan after you make the payment.
                                </div>
                                <div class="mb-3">
                                    <img src="assets/theme/media/illustrations/sigma-1/1.png" class="mw-100 mh-300px theme-light-show" alt="">
                                </div> 
                            </div>
                        </div>      
                        <div v-if="upgradeForm.payment_method == 2">
                            <div class="card card-dashed bg-light-primary border-primary flex-row flex-stack flex-wrap p-5">
                                <div class="d-flex align-items-sm-center">
                                    <div class="symbol symbol-50px me-5">
                                        <span class="symbol-label bg-light">
                                            <i class="bi bi-cash-stack fs-3x text-primary"></i>
                                        </span>
                                    </div>
                                    <div class="d-flex align-items-center flex-row-fluid flex-wrap">                    
                                        <div class="me-20">
                                            <span class="text-gray-800 fs-6 fw-bold">This is your Reference Number</span>
                                            <div class="input-group input-group-sm mt-1">
                                                <input type="text" class="form-control form-control-sm form-control-solid border border-gray-300" style="font-size: 15px !important; font-weight: bolder;" 
                                                    ref="referenceNumber" readonly v-model="licenceReferenceNumber">
                                                <button class="btn btn-sm btn-secondary" :class="{'btn-success': isCopied}" @click="copyToClipboard()">
                                                    <b v-if="!isCopied">Copy</b>
                                                    <b v-if="isCopied">Copied!</b>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 ms-20">
                                            <div class="text-gray-800 fw-bolder d-block fs-3">
                                                <span class="fs-5 me-3">Grand Total: </span>
                                                <span class="fs-2" v-if="activePriceView == 'monthly'">{{this.totals.monthly_price_total}} {{userData.currency_code}}</span>
                                                <span class="fs-2" v-else>{{this.totals.grand_total}} {{userData.currency_code}}</span>
                                            </div>
                                            <div class="fs-6 text-gray-600 fw-semibold">
                                                {{licenceDetails.parameter_type_name}} ({{licenceDetails.period_day}} Days) 
                                                <span v-if="upgradeForm.user_count > 0"> + {{upgradeForm.user_count}} Users </span>
                                            </div>
                                            
                                            <div class="fw-semibold d-block fs-7">When making a payment by bank transfer, you must write this reference number in the Reference field.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-10" v-html="upgradeForm.licence_bank_description" 
                                v-if="upgradeForm.licence_bank_description !== null && upgradeForm.licence_bank_description !== '' && upgradeForm.licence_bank_description !== undefined">
                            </div>
                            <div class="card card-flush">
                                <div class="card-body text-center pb-0">
                                    <div class="fs-6 text-gray-800 mb-5">
                                        We have received your request. You can start using your new plan after you make the payment using this Reference Number. 
                                        <!-- <br> -->
                                        <!-- This window will close in <b class="fs-5" id="payment_timer_text" ref="timerCountdown"> (00:20) </b> seconds to update the license upgrade requests. -->
                                    </div>
                                    <div class="mb-3">
                                        <img src="assets/theme/media/illustrations/sigma-1/1.png" class="mw-100 mh-200px theme-light-show" alt="">
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" :class="{'current': this.activeStep == 4}" v-if="this.activeStep == 4">
                        <div class="card card-flush">
                            <div class="card-body text-center" v-if="!isViewConfirmation">
                                <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
                                    Thank you !!
                                </h1>
                                <div class="fw-semibold fs-6 text-gray-500 mb-7">
                                    We have received your request. You will be redirected to Login page now.
                                </div>
                                <div class="mb-3">
                                    <img src="assets/theme/media/illustrations/sigma-1/2.png" class="mw-100 mh-300px theme-light-show" alt="">
                                </div> 
                            </div>
                            <div class="card-body text-center" v-if="isViewConfirmation">
                                <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
                                    Are you sure?
                                </h1>
                                <div class="fw-semibold fs-6 text-gray-500 mb-7">
                                    The <b>{{licenceDetails.parameter_type_name}}</b> you have selected will be added to your existing license so that you can try it for <b>{{licenceDetails.period_day}}</b> days. Do you confirm?
                                </div>
                                <div class="mb-7 text-center">                                    
                                    <button type="button" class="btn btn-lg btn-success" @click="addLicenceTransaction()" data-kt-indicator="on">
                                        <span v-if="!this.isTransactionProgressing"> Confirm </span>
                                        <span class="indicator-progress" v-if="this.isTransactionProgressing">
                                            Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                                <div class="mb-3">
                                    <img src="assets/theme/media/illustrations/sigma-1/17.png" class="mw-100 mh-300px theme-light-show" alt="">
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer py-3" v-if="activeStep !== 4">
                    <button v-if="activeStep > 1 && activeStep !== 3" type="button" class="btn btn-lg btn-light me-3" @click="onBackStep()">
                        Back
                    </button>
                    <button v-if="activeStep == 1 && !isTrialPackage" type="button" class="btn btn-lg btn-primary float-end" @click="onNextStep()">
                        <span class="indicator-label">
                            Continue
                        </span>
                        <span class="indicator-progress">
                            Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button v-if="activeStep == 1 && isTrialPackage" type="button" class="btn btn-lg btn-primary float-end" @click="onConfirmFreePackage()" data-kt-indicator="on">
                        Continue
                    </button>
                    <button v-if="activeStep == 2" type="button" class="btn btn-lg btn-success float-end" @click="addLicenceTransaction()" data-kt-indicator="on">
                        <span v-if="!this.isTransactionProgressing"> Confirm and Continue </span>
                        <span class="indicator-progress" v-if="this.isTransactionProgressing">
                            Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>

                    <div class="row" v-if="activeStep == 3 && upgradeForm.payment_method == 1">
                        <div class="col-lg-6">
                            <button type="button" class="btn btn-lg btn-danger me-3" @click="onCancelTransaction()">
                                Cancel Request
                            </button>
                        </div>
                        <div class="col-lg-6">
                            <!-- If Credit Card selected -->
                            <!-- One-time Payment -->
                            <stripe-checkout
                                ref="checkoutRef"
                                mode="payment"
                                :pk="this.stripeData.strpk"
                                :session-id="this.stripeData.strsid"
                                @loading="v => loading = v"
                                v-if="activePriceView !== 'monthly' && this.stripeData !== null">
                                <button @click="onSubmitPayment" class="btn btn-lg btn-success float-end" type="button">
                                    Confirm and Proceed to Payment
                                </button>
                            </stripe-checkout> 
                            <!-- Monthly Payment -->
                            <stripe-checkout
                                ref="checkoutRef"
                                mode="subscription"
                                :pk="this.stripeData.strpk"
                                :session-id="this.stripeData.strsid"
                                @loading="v => loading = v"
                                v-if="activePriceView === 'monthly' && this.stripeData !== null">
                                <button @click="onSubmitPayment" class="btn btn-lg btn-success float-end" type="button">
                                    Confirm and Proceed to Payment
                                </button>
                            </stripe-checkout>
                        </div>
                    </div>

                    
                    <button type="button" class="btn btn-lg btn-danger me-3" v-if="activeStep == 3 && upgradeForm.payment_method == 2" @click="onCancelTransaction()">
                        Cancel Request
                    </button>
                    <button type="button" class="btn btn-lg btn-success float-end" v-if="activeStep == 3 && upgradeForm.payment_method == 2" @click="completeUpgrade()">
                        <span class="indicator-label">
                            Complete and Close
                        </span>
                        <span class="indicator-progress">
                            Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div> 
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false});
import { StripeCheckout } from '@james090500/vue-stripe';
export default {
  name: "Upgrade",
  components: {
    StripeCheckout
  },
  data() {
    this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
    return {
      userData: this.$getUser(),
      stripeData: {
        strpk : this.$getUser().stripe ? this.$getUser().stripe.strpk : null,
        strsid: null
      },
      activeStep: 1,
      licenceList: [],
      upgradeForm: {
        licence_id: null,
        user_count: 0,
        user_price: 0,
        user_price_annual: 0,
        payment_method: 1,
        subtotal: 0,
        user_total: 0,
        licence_bank_description: null,
        userCalculatedPrice: 0
      },
      licenceDetails: {},
      activePriceView: 'annual',
      menuSelection: '/LicenceGuideHelppage',
      isViewLicenceGuid : false,
      licencePlanDetails: [],
      totals: {
        sub_total: 0.00,
        vat_amount: 0.00,
        grand_total: 0.00,
        monthly_price_total: 0.00
      },
      licenceReferenceNumber: null,
      isCopied: false,
      isTransactionProgressing: false,
      licenceTransactionId: null,
      isTrialPackage: false,
    };
  },
  computed: { 
    onCalculateSaving() {
        return ((parseFloat(this.licenceDetails.price) * 12) - parseFloat(this.licenceDetails.price_annual)).toFixed(2);
    }
  },
  methods: { 
    getData() {
        this.activeStep = 1;
        this.userMail = this.$getUser().user_mail;
        this.upgradeForm = {
            licence_id: null,
            user_count: 0,
            user_price: 0,
            user_price_annual: 0,
            payment_method: 1,
            subtotal: 0,
            user_total: 0
        };
        this.licenceList = [];
        this.licenceDetails = {};
        this.activePriceView = 'annual';
        this.isTrialPackage = false;

        const parameters = {
          session_id: this.$getUser().session_id
        };  
        axios.post('/api/Lic/Get', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.licenceList = response.data;
                if(this.licenceList.length > 0) this.setDetail(this.licenceList[0]);
                this.helpPageViewIcon();
            }
        });
    },
    setDetail(item) {
        this.licenceDetails = item;        
        this.upgradeForm.licence_id = item.id;
        this.upgradeForm.user_price = item.user_price;
        this.upgradeForm.user_price_annual = item.user_price_annual;
        this.upgradeForm.user_count = 0;
        this.activePriceView = 'annual';
        this.upgradeForm.payment_period = parseInt(this.licenceDetails.period_day / 30);
        this.isTrialPackage = false;
        this.upgradeForm.userCalculatedPrice = 0;

        if(item.parameter_type == 3 && item.package_type == 3) {
            this.isTrialPackage = true;
        }

        if(this.licenceDetails.parameter_type == 5) {
            this.activePriceView = this.licenceDetails.payment_type == 1 ? 'monthly' : 'annual';

            if(this.activePriceView == 'annual') {
                this.upgradeForm.userCalculatedPrice = (((this.upgradeForm.user_price_annual) / this.licenceDetails.period_day) * this.userData.period_end).toFixed(2);
            } else {
                this.upgradeForm.payment_period = parseInt(parseInt(this.userData.period_end) / 30);
            }
        }
        this.calculateSubTotal();
    },
    onChangePrice(priceType) {
        if(priceType == 'plus') {
            this.upgradeForm.user_count++;
        }
        else if(priceType == 'minus'){
            this.upgradeForm.user_count--;
        }
        this.calculateSubTotal();
    },
    calculateSubTotal() {
        if(this.upgradeForm.user_count == null || this.upgradeForm.user_count == '' || this.upgradeForm.user_count == undefined) { this.upgradeForm.user_count = 0; }
        if(this.licenceDetails.parameter_type == 3) { this.upgradeForm.user_count = 0; }
        let licencePrice = this.activePriceView == 'monthly' ? parseFloat(this.licenceDetails.price) : parseFloat(this.licenceDetails.price_annual);
        let userPrice = this.activePriceView == 'monthly' ? parseFloat(this.upgradeForm.user_price) : parseFloat(this.upgradeForm.user_price_annual);

        // Kalan kullanim gunune göre user fiyatı hesaplanıyor
        if(this.licenceDetails.parameter_type == 5) {
            if(this.activePriceView == 'annual') {
                userPrice = (userPrice / this.licenceDetails.period_day) * this.userData.period_end;
            } 
        }
        this.upgradeForm.user_total = (userPrice * parseInt(this.upgradeForm.user_count)).toFixed(2);
        this.upgradeForm.subtotal = (licencePrice + (userPrice * parseInt(this.upgradeForm.user_count))).toFixed(2);

    },
    onChangePeriod(item) {
        this.activePriceView = item;
        this.upgradeForm.user_count = 0;
        this.upgradeForm.user_total = 0;
        this.upgradeForm.subtotal = this.activePriceView == 'monthly' ? parseFloat(this.licenceDetails.price) : parseFloat(this.licenceDetails.price_annual);
        this.calculateSubTotal();
    },
    onConfirmFreePackage() {
        this.activeStep = 4;
        this.isViewConfirmation = true;
    },
    addLicenceTransaction() {
        this.isTransactionProgressing = true;
        // Quantity in LineItems should be updated prior to Total Amount
        var subTotalReedited = parseFloat(this.totals.grand_total).toFixed(2) / 0.1;
        var subTotalMonthlyReedited = parseFloat(this.totals.monthly_price_total).toFixed(2) / 0.1;
        // One-time Payment
        var oneTimeQuantity = parseInt(subTotalReedited);
        // Monthly Payment
        var monthlyQuantity = parseInt(subTotalMonthlyReedited); 

        const parameters = {
          session_id: this.$getUser().session_id,
          target_licence_id: this.licenceDetails.id,
          add_user_count: this.upgradeForm.user_count,
          payment_type: this.activePriceView == 'monthly' ? 1 : 2,
          payment_method: this.upgradeForm.payment_method,
          quantity: this.activePriceView == 'monthly' ? monthlyQuantity : oneTimeQuantity,
          email: this.$getUser().user_mail,
          isTrialPackage: this.isTrialPackage
        };  
        const that = this;
        axios.post('/api/Lic/AddLicenceTransaction', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.licenceReferenceNumber = response.data.LicenceSID;
                this.licenceTransactionId = response.data.LicenceTransactionID;
                this.stripeData.strsid = response.data.StrSID;

                if(this.isTrialPackage) {
                    this.activeStep = 4;
                    this.isViewConfirmation = false;
                    setTimeout(() => {
                        this.$swal("We received your request !", "We’re glad to inform you that we have confirmed your request. Thank you! Please relogin to your account to activate your new plan..!", 'success');
                        this.$removeUser('user_' + this.$getUser().session_id);
                        if(this.$getSessionStorage('is_instant_login')){
                            sessionStorage.clear();
                            window.close();
                        }
                        else{ 
                            sessionStorage.clear();
                            this.$router.push({ name: 'Login' });
                        }
                    }, 3000);
                } else {
                    var userData = ls.get('user_' + sessionStorage.getItem('sid'));          
                    if(userData !== null && userData !== '' && userData !== undefined) {
                        userData.is_pending_licence_exist = 1;
                        ls.set('user_' + sessionStorage.getItem('sid'), userData);
                    }
                    // if(this.upgradeForm.payment_method == 2) {
                    //     this.startTimer();
                    // }
                    this.onNextStep();
                }
            }
        })
        .catch(function (error) {
           console.log(error);
            that.isTransactionProgressing = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isTransactionProgressing = false;
            }, 500);
        });
    },
    onNextStep() {
        this.activeStep++;

        if(this.activeStep == 2){
            this.licencePlanDetails = [];
            this.upgradeForm.payment_method = 1;
            let licenceMonthlyPrice = this.activePriceView == 'monthly' ? parseFloat(this.licenceDetails.price) : parseFloat(this.licenceDetails.price_annual);
            let licencePrice = this.activePriceView == 'monthly' ? (parseFloat(this.licenceDetails.price) * this.upgradeForm.payment_period) : parseFloat(this.licenceDetails.price_annual);
            let licenceUserMonthlyPrice = this.activePriceView == 'monthly' ? parseFloat(this.licenceDetails.user_price) : parseFloat(this.licenceDetails.user_price_annual);
            let licenceVatAmount = this.licenceDetails.is_add_vat == 1 ? parseFloat((licencePrice / 100) * this.licenceDetails.vat_rate).toFixed(2) : 0;
            
            if(this.licenceDetails.parameter_type !== 5) {
                this.licencePlanDetails.push({
                    parameter_type: this.licenceDetails.parameter_type,
                    plan_name: this.licenceDetails.parameter_type_name + ' ' + (this.licenceDetails.package_type !== 0 ? this.licenceDetails.package_type_name : ''),
                    plan_type: this.activePriceView == 'monthly' ? 'Monthly' : (this.licenceDetails.period_day + ' Days'),
                    price: licencePrice.toFixed(2),
                    user_price: licenceUserMonthlyPrice.toFixed(2),
                    vat_amount: this.licenceDetails.is_add_vat == 1 ? licenceVatAmount : 'None',
                    total: (parseFloat(licencePrice) + parseFloat(licenceVatAmount)).toFixed(2),
                    is_add_vat: this.licenceDetails.is_add_vat,
                    user_count: this.licenceDetails.user_count,
                    monthly_price: licenceMonthlyPrice.toFixed(2)
                });
            }


            if(this.upgradeForm.user_count > 0) {
                let licenceUserPrice = this.activePriceView == 'monthly' ? (parseFloat(this.licenceDetails.user_price) * this.upgradeForm.payment_period) : parseFloat(this.licenceDetails.user_price_annual);                
                if(this.licenceDetails.parameter_type == 5 && this.activePriceView == 'annual') {
                    licenceUserPrice = (((licenceUserPrice) / this.licenceDetails.period_day) * this.userData.period_end).toFixed(2);
                }

                licenceUserPrice *= this.upgradeForm.user_count;
                let userVatAmount = this.licenceDetails.is_add_vat == 1 ? parseFloat((licenceUserPrice / 100) * this.licenceDetails.vat_rate).toFixed(2) : 0;

                this.licencePlanDetails.push({
                    parameter_type: 5,
                    plan_name: 'User',
                    plan_type: this.activePriceView == 'monthly' ? 'Monthly' : (this.licenceDetails.period_day + ' Days'),
                    price: parseFloat(licenceUserPrice).toFixed(2),
                    user_price: licenceUserMonthlyPrice.toFixed(2),
                    vat_amount: this.licenceDetails.is_add_vat == 1 ? userVatAmount : 'None',
                    total: (parseFloat(licenceUserPrice) + parseFloat(userVatAmount)).toFixed(2),
                    is_add_vat: this.licenceDetails.is_add_vat,
                    user_count: this.upgradeForm.user_count,
                    monthly_price: parseFloat(licenceUserMonthlyPrice * this.upgradeForm.user_count).toFixed(2)
                });
            }
            let subTotal = 0.00;
            let vatAmount = 0.00;
            let grandTotal = 0.00;
            this.licencePlanDetails.forEach(element => {
                subTotal += parseFloat(element.price);
                vatAmount += parseFloat(element.vat_amount);
                grandTotal += parseFloat(element.total);
            });

            this.totals.sub_total = subTotal.toFixed(2);
            this.totals.vat_amount = vatAmount.toFixed(2);
            this.totals.grand_total = grandTotal.toFixed(2);
            this.totals.monthly_price_total = (grandTotal / parseInt(this.upgradeForm.payment_period)).toFixed(2);
        }

        if(this.activeStep == 3 && this.upgradeForm.payment_method == 2) {
            this.getBankTransferDetails();
        }
    },
    onBackStep() {
        this.activeStep--;
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/LicenceGuideHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.isViewLicenceGuid = response.data;
            }
        })
    },
    openHelpPage(){
        const parameters = {
            session_id : this.$getUser().session_id,
            menu_selection : this.menuSelection
        };
        axios.post('api/HelpPage/PagesAllList', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.PagesAllListlists = response.data;
            window.open(this.menuSelection);            
        })
        .catch(function (error) {
            console.log(error);               
        });    
    },
    getBankTransferDetails() {
        const parameters = {
          session_id: this.$getUser().session_id
        };
        const requestOne =  axios.post('/api/Lic/GetLicenceBankDescription', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.upgradeForm.licence_bank_description = responses[0].data;
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    copyToClipboard() {
        this.$refs.referenceNumber.select();
        document.execCommand('copy');
        this.isCopied = true;        
        setTimeout(() => {
            this.isCopied = false;
        }, 2000);
    }, 
    onCompletePayment(item) {
        this.userMail = this.$getUser().user_mail;
        this.stripeData.strsid = item.stripe_session_id;
        this.licenceList = [];
        this.licenceDetails = {
            parameter_type_name: item.description,
            period_day: item.period_day
        };
        this.upgradeForm.user_count = item.user_count;
        this.activeStep = 3;
        this.activePriceView = item.payment_type == 1 ? 'monthly' : 'annual';
        this.upgradeForm.payment_method = 1;
        this.totals.grand_total = item.grand_total;
        this.totals.monthly_price_total = item.grand_total;
    },
    onSubmitPayment () {  
      // Stripe redirection proceeds here
      this.$refs.checkoutRef.redirectToCheckout();
    },    
    startTimer() {
        const that = this;
        var timer = 20, minutes, seconds;
        const countdownInterval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            var display = document.querySelector('#payment_timer_text');
            if(display.textContent !== null && display.textContent !== undefined && display.textContent !== ''){			
            display.textContent = '('+ minutes + ":" + seconds + ')';
            }
            if (--timer < 0) {
                timer = 0;
                display.textContent = '(00:20)';
                clearInterval(countdownInterval);
                that.completeUpgrade();
            }
        }, 1000);
        this.countdownInterval = countdownInterval;
    },
    completeUpgrade() {
        clearInterval(this.countdownInterval);        
        if(this.$route.name == 'Home') {
            this.$router.go(0);
        } else {
            this.$router.push({ name: 'Home' });
        }
    },
    onCancelTransaction() {
        this.$swal.fire({
            title: "Are you sure you want to cancel this request?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CANCEL REQUEST",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$Progress.start();
                const params = {
                    session_id: this.$getUser().session_id, 
                    transaction_id: this.licenceTransactionId,
                    strsubid: ''
                };   
                const that = this;
                axios.post('api/Lic/CancelLicenceRequest', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') {
                        this.$Progress.finish();
                        this.$swal("Upgrade request has been cancelled..!", "Please relogin to your account..!", 'success');
                        this.$removeUser('user_' + this.$getUser().session_id);
                        if(this.$getSessionStorage('is_instant_login')){
                            sessionStorage.clear();
                            window.close();
                        }
                        else{ 
                            sessionStorage.clear();
                            this.$router.push({ name: 'Login' });
                        } 
                    }
                })
                .catch(function () {
                    that.$Progress.finish();
                });
            }
        }); 
    }
  },
created(){
    this.$removeSessionStorage('strsid'); 
}
};
</script>