<template> 
  <div> 
     <div class="d-flex flex-column flex-root"> 
			<div class="d-flex flex-column flex-center flex-column-fluid">
				<div class="d-flex flex-column flex-center text-center p-10">        
					<div class="card card-flush w-lg-650px py-5">
						<div class="card-body py-15 py-lg-20">
							<h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
								Thank you !!
							</h1>
							<div class="fw-semibold fs-6 text-gray-500 mb-7">
								We have received your payment. You will be redirected to Login page now.
							</div>
							<div class="mb-3">
								<img src="assets/theme/media/illustrations/sigma-1/2.png" class="mw-100 mh-300px theme-light-show" alt="">
							</div> 
						</div>
					</div>   
				</div>
			</div>
		</div>
  </div>  
</template>

<script>
import axios from "axios"; 
export default {
  name: "UpgradeSuccess",
  components: {},
  data() {
    return {
      userData: this.$getUser()
    };
  },
  computed: {},
  methods: { 
    getData() {
        const parameters = {
          session_id: this.$getUser().session_id
        };
        axios.post('api/Payment/Control', parameters, {'Content-Type': 'application/json'}) 
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            setTimeout(() => {
              this.$swal("We received your payment !", "We’re glad to inform you that we have confirmed your payment. Thank you! Please relogin to your account to activate your new plan..!", 'success');
              this.$removeUser('user_' + this.$getUser().session_id);
              if(this.$getSessionStorage('is_instant_login')){
                  sessionStorage.clear();
                  window.close();
              }
              else{ 
                  sessionStorage.clear();
                  this.$router.push({ name: 'Login' });
              }
            }, 3000);
          }
          else{
            this.$swal("Oops..", "A problem occured while activating your licence. Please contact system administrator for more details.", 'error');
            this.$removeUser('user_' + this.$getUser().session_id);
              if(this.$getSessionStorage('is_instant_login')){
                  sessionStorage.clear();
                  window.close();
              }
              else{ 
                  sessionStorage.clear();
                  this.$router.push({ name: 'Login' });
              }
          }
        })
        .catch(function (error) {
            console.log(error);
        });  
    }  
  },
created(){
    this.getData();
}
};
</script>