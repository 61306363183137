<template>
  <div v-if="isShow">  
    <div v-if="userData.is_quick_mode !== 1 && userData.is_pending_licence_exist == 0 && userData.period_end > 0 && (userData.licence_type == 0 || (userData.licence_type == 1 && userData.period_end <= 30))"
        class="card mb-5 mb-xxl-8 mt-4 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow-sm bg-abstract-2">
        <div class="card-header min-h-50px">
            <div class="card-title text-gray-700 fs-5"></div>
            <div class="card-toolbar">
                <span class="card-label fw-bold text-gray-800 mb-1">
                    <span v-if="userData.period_end > 0">
                        {{ $t('Your current plan is') }}
                        <b class="fw-boldest">{{ userData.licence_name }}</b>.
                        {{ $t('It will expire in') }} <b :class="{'text-danger': userData.period_end <= 15}">{{parseInt(userData.period_end)}}</b> {{ $t('days') }}.

                        <span v-if="userData.is_v_ac">
                            {{ $t('You have included') }} <b>{{ $t('Accounting Module') }}</b> {{ $t('in your plan') }}.
                            {{ $t('Accounting will expire in') }} <b :class="{'text-danger': userData.accounting_period_end <= 15}">{{parseInt(userData.accounting_period_end)}}</b> {{ $t('days') }}.
                        </span> 
                    </span>
                    <span class="text-danger" v-if="userData.period_end <= 0">
                        {{ $t('Your') }}
                        <b v-if="userData.licence_type == 0">{{ $t('Trial Plan') }}</b>
                        <b v-if="userData.licence_type == 1">{{ $t('Licence') }}</b> is 
                        <span v-if="userData.period_end == 1">{{ $t('expires today') }}.</span>
                        <span v-if="userData.period_end <= 0">{{ $t('expired') }}.</span>
                    </span>
                    {{ $t('Click') }} <b>"{{ $t('Upgrade your plan') }}"</b> {{ $t('button to upgrade') }}.
                </span>                
                <a href="#" class="btn btn-sm btn-warning text-dark fw-bolder ms-10 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan" @click="openUpgradeModal()">
                    <span class="svg-icon svg-icon-2 text-dark">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                        <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                        </svg>
                    </span>
                    {{ $t('Upgrade your plan') }}
                </a>
            </div>
        </div>
    </div>

    <div class="card rounded shadow mb-5 mb-xxl-8 mt-4" v-if="userData.is_pending_licence_exist > 0">            
        <div class="card-header rounded collapsible cursor-pointer rotate" data-bs-toggle="collapse" data-bs-target="#kt_docs_card_collapsible"
            :class="{'border-bottom border-2 border-primary': !isCollapsePendingPlans}">
            <div class="card-title w-75"> 
                <div class="d-flex align-items-center fs-5 text-gray-800">
                    <span class="svg-icon svg-icon-primary svg-icon-2hx">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                        <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                        </svg>
                    </span>
                    <div class="d-flex flex-column ms-5">
                        <h3 class="mb-1 mt-5">{{ $t('You have pending requests') }}.</h3>
                        <span class="fs-6 mb-5">
                            {{ $t('Payment is required for your license upgrade request. After payment, you can start using the plan you requested.') }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="card-toolbar">
                <div class="d-flex flex-column justify-content-center w-100">
                    <div class="d-flex fs-6 fw-semibold align-items-center justify-content-end my-3">
                        <button type="button" class="btn btn-lg btn-icon btn-color-primary btn-active-light-primary border bg-light-primary collapsible cursor-pointer rotate collapsed" 
                            data-bs-toggle="collapse" data-bs-target="#kt_pending_plans_collapsible" @click="getPendingPlans()">                        
                            <span class="svg-icon svg-icon-3x svg-icon-primary rotate-180">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div class="d-flex fs-7 text-gray-500 fw-semibold align-items-center" v-if="!isCollapsePendingPlans">
                        {{ $t('Click for details') }}
                    </div>
                </div>
            </div>
        </div>
        <div id="kt_pending_plans_collapsible" class="collapse">
            <div class="card-body px-5 py-10">
                <div class="row">
                    <div class="col-lg-12 table-responsive">
                        <table class="table table-row-bordered border align-middle gy-5 gs-3">
                            <thead class="border-bottom border-gray-200 fs-6 text-gray-700 fw-bold bg-light bg-opacity-75">
                                <tr>
                                    <td>{{ $t('Date') }}</td>
                                    <td class="mw-200px">{{ $t('Plan Name') }}</td>
                                    <td>{{ $t('Payment Type') }}</td>
                                    <td>{{ $t('Payment Method') }}</td>
                                    <td class="text-end">{{ $t('Price') }}</td>
                                    <td class="text-end">{{ $t('VAT') }}</td>
                                    <td class="text-end">{{ $t('Grand Total') }}</td>
                                    <td class="text-end">{{ $t('Reference Number') }}</td>
                                    <td class="text-end"></td>
                                </tr>
                            </thead>
                            <tbody class="fw-semibold fs-6 text-gray-700">
                                <tr v-for="(item, index) in pendingLicencesList" v-bind:key="item">
                                    <td>{{previewDate(item.created_at)}}</td>
                                    <td>
                                        {{item.description}}
                                        <span v-if="item.user_count > 0"> (+{{item.user_count}} User)</span>
                                        <div v-if="item.payment_type == 1"> ({{item.grand_total}} {{userData.currency_code}} x {{item.installment_count}} installments) </div>
                                    </td>
                                    <td>{{item.payment_type_name}}</td>
                                    <td>{{item.payment_method_name}}</td>
                                    <td class="text-end">{{item.price}}</td>
                                    <td class="text-end">{{item.vat_amount}}</td>
                                    <td class="text-end text-gray-800 fw-bolder">{{item.grand_total}} {{userData.currency_code}} </td>
                                    <td class="text-end text-gray-800 fw-bolder">{{item.licence_reference_number}}</td>
                                    <td class="text-end">
                                        <div class="row">
                                            <div class="col-lg-6" v-if="item.payment_method == 1 && index == 0">
                                                <button class="btn btn-success btn-xs" type="button" data-bs-toggle="modal" 
                                                    data-bs-target="#kt_modal_upgrade_plan" @click="openUpgradeModal(true, item)"> 
                                                    {{ $t('Pay') }}
                                                </button>
                                            </div>
                                            <div class="col-lg-6" v-if="index == 0">
                                                <button class="btn btn-danger btn-xs" type="button" @click="onCancelLicenceRequest(item)"> 
                                                    {{ $t('Cancel') }}
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card rounded shadow bg-light-danger border border-danger mb-5 mb-xxl-8 mt-4" v-if="userData.is_pending_payment_exist > 0">            
        <div class="card-header rounded">
            <div class="card-title w-75"> 
                <div class="d-flex align-items-center fs-5 text-gray-800">
                    <span class="svg-icon svg-icon-danger svg-icon-2hx">
                        <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M12 6.20001V1.20001H2V6.20001C2 6.50001 2.1 6.70001 2.3 6.90001L5.6 10.2L2.3 13.5C2.1 13.7 2 13.9 2 14.2V19.2H12V14.2C12 13.9 11.9 13.7 11.7 13.5L8.4 10.2L11.7 6.90001C11.9 6.70001 12 6.50001 12 6.20001Z" fill="currentColor"/>
                        <path d="M13 2.20001H1C0.4 2.20001 0 1.80001 0 1.20001C0 0.600012 0.4 0.200012 1 0.200012H13C13.6 0.200012 14 0.600012 14 1.20001C14 1.80001 13.6 2.20001 13 2.20001ZM13 18.2H10V16.2L7.7 13.9C7.3 13.5 6.7 13.5 6.3 13.9L4 16.2V18.2H1C0.4 18.2 0 18.6 0 19.2C0 19.8 0.4 20.2 1 20.2H13C13.6 20.2 14 19.8 14 19.2C14 18.6 13.6 18.2 13 18.2ZM4.4 6.20001L6.3 8.10001C6.7 8.50001 7.3 8.50001 7.7 8.10001L9.6 6.20001H4.4Z" fill="currentColor"/>
                        </svg>
                    </span>
                    <div class="d-flex flex-column ms-5">
                        <h3 class="mb-1 mt-5">{{ $t('We can´t receive your payment') }}.</h3>
                        <span class="fs-6 mb-5">                            
                            {{ $t('There is a problem receiving your payment. Please review the payment. Payment is required to continue using the license') }}.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-row row mt-4">
        <div class="d-flex flex-column flex-row-auto col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0" v-if="userData.parameter_type == 0"> 
            <div class="card h-md-100 d-flex flex-column-fluid">
                <div class="card-body">
                    <h1 class="fw-bolder text-gray-900 mb-5">
                        {{ $t('Hi') }} {{ getOnlyUserName }},
                    </h1>
                    <div class="fw-bold fs-5 text-gray-600 mb-7">
                        {{ $t('Thank you for joining us') }}.
                    </div>
                    <div class="fw-semibold fs-6 text-gray-500 mb-7">                        
                        {{ $t('During your trial period, you can create unlimited accounts for your customers and vendors and organize their invoices') }}. 
                        {{ $t('You can enter your collections and expenses and monitor your income at any time') }}.
                    </div>
                    <div class="fw-semibold fs-6 text-gray-500 mb-7">
                        {{ $t('You can write to us when you need, we will try to answer within 24 hours on average') }}. 
                        {{ $t('We believe that we will be even stronger with you') }}.
                    </div>
                    <div class="mb-0 text-center">
                        <img src="assets/theme/media/svg/general/icon-positive-vote-1.svg" alt="icon" class="min-h-100px"/>
                    </div>           
                </div>
            </div> 
        </div>
        <div v-if="userData.is_quick_mode !== 1 && (this.$isCredentialActive(1,'') || this.$isCredentialActive(2,''))" class="col-lg-6 col-xl-6 col-xxl-6 d-flex flex-column flex-row-auto">
            <div class="card card-flush d-flex flex-column-fluid">
                <div class="card-body px-5 py-10">
                    <div class="row text-center g-6 mb-6">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" v-if="this.$isCredentialActive(10,'')">
                            <router-link to="/CustomerList">
                                <a href="" class="card border-hover-danger h-100 px-md-3 home-card-menu p-10 bg-light-primary">
                                    <h2>
                                        <span class="svg-icon svg-icon-primary svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/>
                                                <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/>
                                                <path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/>
                                                <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </h2>
                                    <h5 class="card-title card-title-custom fs-7 mt-3 text-gray-800">
                                    {{ $t('Customer') }}
                                    </h5>
                                </a>
                            </router-link>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" v-if="this.$isCredentialActive(11,'')">
                            <router-link to="/SupplierList">                    
                                <a href="" class="card border-hover-danger h-100 px-md-3 home-card-menu p-10 bg-light-primary">
                                    <h2>
                                        <span class="svg-icon svg-icon-indigo svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z" fill="currentColor"/>
                                            <path d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </h2>   
                                    <h5 class="card-title card-title-custom fs-7 mt-3 text-gray-800">
                                        {{ $t('Supplier') }}
                                    </h5>
                                </a>
                            </router-link>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" v-if="this.$isCredentialActive(16,'')">
                            <router-link to="/StockList">
                                <a href="" class="card border-hover-danger h-100 px-md-3 home-card-menu p-10 bg-light-primary">
                                    <h2>
                                        <span class="svg-icon svg-icon-warning svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z" fill="currentColor"/>
                                            <path d="M7 16H6C5.4 16 5 15.6 5 15V13H8V15C8 15.6 7.6 16 7 16Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z" fill="currentColor"/>
                                            <path d="M18 16H17C16.4 16 16 15.6 16 15V13H19V15C19 15.6 18.6 16 18 16Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z" fill="currentColor"/>
                                            <path d="M7 5H6C5.4 5 5 4.6 5 4V2H8V4C8 4.6 7.6 5 7 5Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z" fill="currentColor"/>
                                            <path d="M18 5H17C16.4 5 16 4.6 16 4V2H19V4C19 4.6 18.6 5 18 5Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </h2>
                                    <h5 class="card-title card-title-custom fs-7 mt-3 text-gray-800">
                                        {{ $t('Stock & Service') }}
                                    </h5>
                                </a>
                            </router-link>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" v-if="this.$isCredentialActive(20,'')">
                            <router-link to="/InvoiceCreate">
                                <a href="" class="card border-hover-danger h-100 px-md-3 home-card-menu p-10 bg-light-primary">
                                    <h2>
                                        <span class="svg-icon svg-icon-primary svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M3 3V17H7V21H15V9H20V3H3Z" fill="currentColor"/>
                                            <path d="M20 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V21C21 21.6 20.6 22 20 22ZM19 4H4V8H19V4ZM6 18H4V20H6V18ZM6 14H4V16H6V14ZM6 10H4V12H6V10ZM10 18H8V20H10V18ZM10 14H8V16H10V14ZM10 10H8V12H10V10ZM14 18H12V20H14V18ZM14 14H12V16H14V14ZM14 10H12V12H14V10ZM19 14H17V20H19V14ZM19 10H17V12H19V10Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </h2>
                                    <h5 class="card-title card-title-custom fs-7 mt-3 text-gray-800">
                                        {{ $t('Invoice') }}
                                    </h5>
                                </a>
                            </router-link>
                        </div>
                    </div>
                    <div class="row text-center g-6">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" v-if="this.$isCredentialActive(21,'')">
                            <router-link to="/CashTransactionCreate">                    
                                <a href="" class="card border-hover-danger h-100 px-md-3 home-card-menu p-10 bg-light-primary">
                                    <h2>
                                        <span class="svg-icon svg-icon-success svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M12.5 22C11.9 22 11.5 21.6 11.5 21V3C11.5 2.4 11.9 2 12.5 2C13.1 2 13.5 2.4 13.5 3V21C13.5 21.6 13.1 22 12.5 22Z" fill="currentColor"/>
                                            <path d="M17.8 14.7C17.8 15.5 17.6 16.3 17.2 16.9C16.8 17.6 16.2 18.1 15.3 18.4C14.5 18.8 13.5 19 12.4 19C11.1 19 10 18.7 9.10001 18.2C8.50001 17.8 8.00001 17.4 7.60001 16.7C7.20001 16.1 7 15.5 7 14.9C7 14.6 7.09999 14.3 7.29999 14C7.49999 13.8 7.80001 13.6 8.20001 13.6C8.50001 13.6 8.69999 13.7 8.89999 13.9C9.09999 14.1 9.29999 14.4 9.39999 14.7C9.59999 15.1 9.8 15.5 10 15.8C10.2 16.1 10.5 16.3 10.8 16.5C11.2 16.7 11.6 16.8 12.2 16.8C13 16.8 13.7 16.6 14.2 16.2C14.7 15.8 15 15.3 15 14.8C15 14.4 14.9 14 14.6 13.7C14.3 13.4 14 13.2 13.5 13.1C13.1 13 12.5 12.8 11.8 12.6C10.8 12.4 9.99999 12.1 9.39999 11.8C8.69999 11.5 8.19999 11.1 7.79999 10.6C7.39999 10.1 7.20001 9.39998 7.20001 8.59998C7.20001 7.89998 7.39999 7.19998 7.79999 6.59998C8.19999 5.99998 8.80001 5.60005 9.60001 5.30005C10.4 5.00005 11.3 4.80005 12.3 4.80005C13.1 4.80005 13.8 4.89998 14.5 5.09998C15.1 5.29998 15.6 5.60002 16 5.90002C16.4 6.20002 16.7 6.6 16.9 7C17.1 7.4 17.2 7.69998 17.2 8.09998C17.2 8.39998 17.1 8.7 16.9 9C16.7 9.3 16.4 9.40002 16 9.40002C15.7 9.40002 15.4 9.29995 15.3 9.19995C15.2 9.09995 15 8.80002 14.8 8.40002C14.6 7.90002 14.3 7.49995 13.9 7.19995C13.5 6.89995 13 6.80005 12.2 6.80005C11.5 6.80005 10.9 7.00005 10.5 7.30005C10.1 7.60005 9.79999 8.00002 9.79999 8.40002C9.79999 8.70002 9.9 8.89998 10 9.09998C10.1 9.29998 10.4 9.49998 10.6 9.59998C10.8 9.69998 11.1 9.90002 11.4 9.90002C11.7 10 12.1 10.1 12.7 10.3C13.5 10.5 14.2 10.7 14.8 10.9C15.4 11.1 15.9 11.4 16.4 11.7C16.8 12 17.2 12.4 17.4 12.9C17.6 13.4 17.8 14 17.8 14.7Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </h2>   
                                    <h5 class="card-title card-title-custom fs-7 mt-3 text-gray-800">
                                        {{ $t('Cash') }}
                                    </h5>
                                </a>
                            </router-link>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" v-if="this.$isCredentialActive(22,'')">
                            <router-link to="/BankTransactionCreate">
                                <a href="" class="card border-hover-danger h-100 px-md-3 home-card-menu p-10 bg-light-primary">
                                    <h2>
                                        <span class="svg-icon svg-icon-danger svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </h2>
                                    <h5 class="card-title card-title-custom fs-7 mt-3 text-gray-800">
                                        {{ $t('Bank') }}
                                    </h5>
                                </a>
                            </router-link>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" v-if="this.$isCredentialActive(23,'')">
                            <router-link to="/ExpenseTransactionCreate">                    
                                <a href="" class="card border-hover-danger h-100 px-md-3 home-card-menu p-10 bg-light-primary">
                                    <h2>
                                        <span class="svg-icon svg-icon-info svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"/>
                                            <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </h2>   
                                    <h5 class="card-title card-title-custom fs-7 mt-3 text-gray-800">
                                        {{ $t('Expense') }}
                                    </h5>
                                </a>
                            </router-link>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" v-if="this.$isCredentialActive(24,'')">
                            <router-link to="/OffsettingCreate">
                                <a href="" class="card border-hover-danger h-100 px-md-3 home-card-menu p-10 bg-light-primary">
                                    <h2>
                                        <span class="svg-icon svg-icon-warning svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="currentColor"/>
                                            <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="currentColor"/>
                                            <path d="M10.3629 14.0084L8.92108 12.6429C8.57518 12.3153 8.03352 12.3153 7.68761 12.6429C7.31405 12.9967 7.31405 13.5915 7.68761 13.9453L10.2254 16.3488C10.6111 16.714 11.215 16.714 11.6007 16.3488L16.3124 11.8865C16.6859 11.5327 16.6859 10.9379 16.3124 10.5841C15.9665 10.2565 15.4248 10.2565 15.0789 10.5841L11.4631 14.0084C11.1546 14.3006 10.6715 14.3006 10.3629 14.0084Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </h2>
                                    <h5 class="card-title card-title-custom fs-7 mt-3 text-gray-800">
                                        {{ $t('Offsetting') }}
                                    </h5>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="this.$isCredentialActive(20,'') && userData.is_quick_mode !== 1 && userData.parameter_type !== 0" class="col-lg-6 col-xl-6 col-xxl-6 d-flex flex-column flex-row-auto"> 
            <div class="card card-flush h-lg-100 d-flex flex-column-fluid"> 
                <div class="card-header pt-7"> 
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bold text-gray-800">
                            <span v-if="widgetType == 1">{{ $t('Sales') }}</span>
                            <span v-if="widgetType == 2">{{ $t('Purchase') }}</span>
                            {{ $t('Invoices') }}
                        </span>
                        <!-- <span class="text-gray-400 mt-1 fw-semibold fs-6">Updated 37 minutes ago</span> -->
                    </h3>
                    <div class="card-toolbar">
                        <div class="row">
                            <div class="col-lg-12">
                                <Select2 v-model="widgetType" :options="widgetTypeList" @select="getStatsForWidget()" :settings="{ width: '100%' }" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-6">
                    <div class="table-responsive">
                        <table class="table table-row-dashed align-middle gs-0 gy-3 my-0">
                            <thead>
                                <tr class="fw-bolder text-gray-700">
                                    <th class="p-0 pb-3 min-w-175px text-start">{{ $t('DATE TYPE') }}</th>
                                    <th class="p-0 pb-3 min-w-100px text-end">{{ $t('AMOUNT') }}</th>
                                    <th class="p-0 pb-3 min-w-100px text-end">{{ $t('TOTAL') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in statsList" v-bind:key="item">
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="symbol symbol-50px me-3">
                                                <div class="symbol-label fs-2 fw-semibold text-success">
                                                    <i v-if="item.date_type == 0" class="bi bi-receipt fs-1 text-success"></i>
                                                    <i v-if="item.date_type == 1" class="bi bi-cash-stack fs-1 text-primary"></i>
                                                    <i v-if="item.date_type == 2" class="bi bi-bank fs-1 text-info"></i>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-start flex-column">
                                                <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{{item.date_type_name}}</a>
                                                <span class="text-gray-400 fw-semibold d-block fs-7">{{item.invoice_type_name}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-end pe-0">
                                        <span class="text-gray-600 fw-bold fs-6">{{item.amount}}</span>
                                    </td>
                                    <td class="text-end pe-0">
                                        <span class="text-gray-600 fw-bold fs-6">{{item.gross_amount_local}} {{ userData.currency_code }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
      
    <div class="modal fade" id="kt_modal_upgrade_plan" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-xxl">
            <div class="modal-content rounded"  >
                <div class="modal-header justify-content-end border-0 p-0">
                    <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" @click="onRefreshUserData()">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-1"> <Upgrade ref="UpgradeModal"/> </div>
            </div>
        </div>
    </div>
  </div>
</template>
<style scoped>
    .timeline-label:before {
        left: 126px !important;
    }
    h3 {
        margin: 40px 0 0;
    }
    body{
        top: 0 !important;
    }  
  .skiptranslate {
    display: none !important;
  } 
</style>  
<script> 
import axios from 'axios' 
import moment from 'moment';
import SecureLS from "secure-ls";  
import Swal from 'sweetalert2';
import Upgrade from '../components/parts/Upgrade.vue';

const ls = new SecureLS({ isCompression: false });
axios.interceptors.response.use((response) => {
  if (response.data.error) {    
    new Swal({
        icon: 'error',
        title: 'Error...',
        text: response.data.error
    });

    if(sessionStorage.getItem('sid') !== undefined && sessionStorage.getItem('sid') !== null && sessionStorage.getItem('sid') !== '' && response.data.error !== undefined && response.data.error !== null && response.data.error !== '') {
        if((response.data.error.toLowerCase()).includes('session not found') || response.data.error.includes('UA-PWC') ){
            let timerInterval;
            Swal.fire({
                title: 'Session terminated!',
                html: response.data.error.includes('UA-PWC') ? 'The password has been changed successfully. </br> You will be automatically signed out in <b></b> seconds. You must log in to the system again to continue.' : 'Session terminated due to some reasons. You will be automatically signed out in <b></b> seconds. Please logged in to the system again to continue.<div><b>Some of these reasons may be;</b> </br> <i class="bi bi-dot"></i>Changes in user credentials. </br> <i class="bi bi-dot"></i>Updates/upgrades in license or company information. </br> <i class="bi bi-dot"></i>Starting a different session with the same information.</div>',
                icon: 'warning',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                timer: 10000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                    const b = Swal.getHtmlContainer().querySelector('b');
                    b.textContent = 10;
                    timerInterval = setInterval(() => {
                        b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0)
                    }, 1000)
                },
                willClose: () => {
                    clearInterval(timerInterval);
                    sessionStorage.clear();
                    window.location.href = "/Login";
                }
            });
        }
    }
  }
  return response;
}, 
(error) => {
  return Promise.reject(error.message);
}); 
export default {
  name: 'Home',
  components: {
    Upgrade
  },
  data() {
    return {
      userData: this.$getUser(),
      tasks: [],
      page: 1,
      page_size: 5,
      isTimelineDrawerOpen: false,
      pendingLicencesList: [],
      isCollapsePendingPlans: false,
      widgetType: 1,
      widgetTypeList: [{id: 1, text: 'Sales'}, {id: 2, text: 'Purchase'}],
      statsList: [],
      isShow: false
    };
  },
  props: {msg: String},
  computed:{
    searchableList() { 
        return this.tasks.slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    getOnlyUserName() {
      return (this.userData !== null && this.userData !== undefined && this.userData !== '') ? this.userData.name_surname.split(' ')[0] : '';
    },
  },
  methods:{ 
    onRefreshUserData() {
        if(this.$refs.UpgradeModal.licenceTransactionId !== null && this.$refs.UpgradeModal.licenceTransactionId !== '' && this.$refs.UpgradeModal.licenceTransactionId !== undefined) {
            if(this.$route.name == 'Home') this.$router.go(0);
            else this.$router.push({ name: 'Home' });
        }
    },
    InstantLogin(){
        if(sessionStorage.getItem('sid') != null && sessionStorage.getItem('sid') != '' && sessionStorage.getItem('sid') != undefined){
            if(this.$route.query.s != null){
                const parameters = { 
                    new_session_id: sessionStorage.getItem('sid')
                }; 
                axios.post('/api/Auth/InstantAuthUser', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        response.data.company_name = this.$unlock(response.data.company_name);
                        response.data.company_vkn = this.$unlock(response.data.company_vkn);
                        response.data.name_surname = this.$unlock(response.data.name_surname);
                        response.data.user_mail = this.$unlock(response.data.user_mail);
                        response.data.company_country_code = this.$unlock(response.data.company_country_code);
                        response.data.phone_country_code = this.$unlock(response.data.phone_country_code);
                        if(response.data.stripe) {
                            response.data.stripe.oid = this.$unlock(response.data.stripe.oid);
                            response.data.stripe.mid = this.$unlock(response.data.stripe.mid);
                            response.data.stripe.strpk = this.$unlock(response.data.stripe.strpk);
                            response.data.stripe.psurl = this.$unlock(response.data.stripe.psurl);
                            response.data.stripe.flurl = this.$unlock(response.data.stripe.flurl);
                        }
                        ls.set('user_' + sessionStorage.getItem('sid'), response.data);
                        sessionStorage.setItem('is_instant_login', true);
                        sessionStorage.setItem('activeYear', response.data.period_id);
                        this.userData = ls.get('user_' + sessionStorage.getItem('sid'));
                        window.location.href= "/";
                        this.getStatsForWidget();
                    }

                    setTimeout(() => {
                        this.isShow = true;
                    }, 1000);
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
            if(this.$getUser().session_id !== null && this.$getUser().session_id !== '' && this.$getUser().session_id !== undefined){
                this.GetGridSessions();
            }
        }
    },
    GetGridSessions(){
        const parameters = { 
            session_id: this.$getUser().session_id,
            user_id: this.$getUser().id,
            widget_type: this.widgetType
        };
        const requestOne =  axios.post('/api/Users/GetGridSessions', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Widgets/GetStats', parameters, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            responses[0].data.forEach((item) => { 
                localStorage.setItem('dx_' + item.table_code, item.state_detail);
            });
            this.statsList = responses[1].data; 
            this.isShow = true;
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    getPendingPlans() {
        this.isCollapsePendingPlans = !this.isCollapsePendingPlans;

        if(this.isCollapsePendingPlans) {
            const parameters = {
            session_id: this.$getUser().session_id
            };  
            axios.post('/api/Lic/GetPendingActions', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.pendingLicencesList = response.data;
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    },
    openUpgradeModal(isProceedPayment, item) {
        if(isProceedPayment) {
            this.$refs.UpgradeModal.onCompletePayment(item);
        } else {
            this.$refs.UpgradeModal.getData();
        }
    },
    onCancelLicenceRequest(item) {
        this.$swal.fire({
            title: "Are you sure you want to cancel this request?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CANCEL REQUEST",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$Progress.start();
                const params = {
                    session_id: this.$getUser().session_id, 
                    transaction_id: item.id,
                    strsubid: ''
                };   
                const that = this;
                axios.post('api/Lic/CancelLicenceRequest', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$Progress.finish();
                        this.$swal("Upgrade request has been cancelled..!", "Please relogin to your account..!", 'success');
                        this.$removeUser('user_' + this.$getUser().session_id);
                        if(this.$getSessionStorage('is_instant_login')){
                            sessionStorage.clear();
                            window.close();
                        }
                        else{ 
                            sessionStorage.clear();
                            this.$router.push({ name: 'Login' });
                        }                     
                    }
                })
                .catch(function () {
                    that.$Progress.finish();
                });    
            }
        }); 
    },
    getStatsForWidget() {
        this.statsList = [];
        const parameters = {
            session_id: this.$getUser().session_id, 
            widget_type: this.widgetType
        };  
        axios.post('/api/Widgets/GetStats', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.statsList = response.data;
           }
        })
        .catch(function (error) {
           console.log(error);
        });
    }
  },
  mounted() {
    this.InstantLogin();
  }
}
</script>