<template>
  <div class="d-flex flex-column flex-column-fluid flex-lg-row">
    <div class="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
      <div class="d-flex flex-center flex-lg-start flex-column text-start">
        <a href="/" class="mb-7">
          <img alt="Logo" src="assets/theme/media/logos/logo-cc-white.png">
        </a>
        <h2 class="text-white fw-normal m-0 ms-4">Branding tools designed for your business</h2>
      </div>
    </div>
    <div class="d-flex flex-center w-lg-50 p-10">
      <div class="card rounded-3 w-md-550px pt-15 pb-15 mt-20 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow" style="background-image: url('assets/theme/media/svg/shapes/abstract-4.svg')">
        <div v-if="!isSendResetLink" class="card-body p-20 p-lg-20">
          <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework fw-row" v-if="!isSendResetLink" @submit.prevent="onBeforeSubmit()">
            <div class="text-center mb-10">
                <h1 class="fw-bolder mb-3">
                    Forgot Password ?
                </h1>
                <div class="text-gray-500 fw-semibold fs-6">
                    Enter your e-mail address to receive a password reset link.
                </div>
            </div>
            <div class="fv-row mb-8 fv-plugins-icon-container">
              <input type="text" placeholder="Email" name="email" autocomplete="off" class="form-control bg-white" v-model="form.user_mail" :class="{'border border-danger': isValidEmail == false}"> 
              <small class="text-danger" v-if="!isValidEmail && form.user_mail !== null && form.user_mail !== '' ">Email is not valid</small>
            </div>

            <div class="d-flex flex-wrap justify-content-center pb-lg-0">
              <button @click="onBeforeSubmit()" type="button" class="btn btn-primary me-4" data-kt-indicator="on" :disabled="isValidEmail !== true">                      
                <span v-if="!this.isProgressing"> Reset Password </span>
                <span class="indicator-progress" v-if="this.isProgressing">
                  Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
              <router-link to="/Login">
                <a href="#" class="btn btn-light">
                    Cancel
                </a>
              </router-link>
            </div>
          </form>
        </div>
        
        <div v-if="isSendResetLink" class="card-body p-20 p-lg-20 text-center">
          <h1 class="fw-bolder text-gray-900 mb-5">
            Reset link has been sent.
          </h1>
          <div class="fs-6 mb-5">
            <span class="fw-semibold text-gray-500 mb-5">To set your new password, please click the reset password link that we sent to your e-mail address.</span>
          </div>
          <div class="mb-11">
            <img src="/assets/theme/media/illustrations/unitedpalms-1/16.png" class="mw-100 mh-200px theme-light-show" alt="">
          </div>
          <div class="fs-6 mb-5">
            <span class="fw-semibold text-gray-500">Didn’t receive an email? <br> Please check your spam folder or</span>

            <a href="#" class="link-primary fw-bold" @click="onTryAgain()"> try again</a>
          </div>
          <div class="mb-0">
            <router-link to="/Login">
              <button type="button" class="btn btn-sm btn-primary">
                Back to Login
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { load } from 'recaptcha-v3';

export default {
  name: "ForgotPassword",
  data() {
    return {
      form: {
        user_mail: ''
      },
      isSendResetLink: false,
      isProgressing: false,
      isVerifyRecaptcha: false,
      rc_sk: ''
    };
  },
  computed: {
    isValidEmail() {
      return (this.form.user_mail == null || this.form.user_mail == '' || this.form.user_mail == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.user_mail);
    }
  },
  methods: {
    getData(){
      document.body.style.backgroundImage = "url('/assets/theme/media/auth/bg7-dark.jpg')";
      document.body.classList.add('app-blank');
      document.body.classList.add('bgi-size-cover');
      document.body.classList.add('bgi-position-center');
      document.body.classList.add('bgi-no-repeat');

      axios.get('/api/GeneralUserParameters/GetRc').then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.isVerifyRecaptcha = response.data.is_view_rc_fp == 1 ? true : false;
            this.rc_sk = response.data.sk;
            this.loadReCaptcha();
          }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    async loadReCaptcha() {
			if(this.rc_sk !== null && this.rc_sk !== '' && this.rc_sk !== undefined) {
        const recaptcha = await load(this.rc_sk);
        if(!this.isVerifyRecaptcha) recaptcha.hideBadge();
        else recaptcha.showBadge();
      }
    },
    async onBeforeSubmit() {
      if(this.isVerifyRecaptcha) {
        const recaptcha = await load(this.rc_sk);
        const token = await recaptcha.execute('login');
        const parameters = {
          token: token,
          sk: this.rc_sk
        };
        const that = this;
        axios.post("/api/Auth/CheckRC", parameters, { "Content-Type": "application/json" })
        .then((response) => {
          if (response.data.success == true && response.data.score > 0.5) {
            this.sendResetLink();
          } else {            
            that.$swal("Failed", "ReCaptcha error..!", 'error'); 
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      } else {
        this.sendResetLink();
      }
    },
    sendResetLink() {
      if(!this.form.user_mail) {
        this.$swal.fire({
          icon: 'error',
          title: 'Email address is required!'
        })
      } else {
        this.isProgressing = true;
        const parameters = {
          user_mail: this.form.user_mail
        };  
        const that = this; 
        axios.post('/api/Auth/SendResetLink', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.isSendResetLink = true;
          }
        })
        .catch(function() {
          new Swal( { icon: 'error', title: 'Error...', text: 'The mail server information is not defined or contains incorrect information. Please make sure the information is correct and try again...!' });
        })
        .finally(function () { 
          setTimeout(() => {
            that.isProgressing = false;
          }, 500);
        }); 
      }
    },
    onTryAgain() {
      this.form.user_mail = null;
      this.isSendResetLink = false;
    }
  },
  created() {
     this.getData();
  }
};
</script>